"use client";

import React, { useEffect } from "react";
import { useSearchParams } from "next/navigation";

/* Contexts */
import { AUTH_ACTIONS, useAuth } from "../../contexts/auth/auth.context";
import { getUserProfile } from "@src/service/auth/handler";

/* Stores */
import { useLoginModal } from "@stores";

/* Hooks */
import { useCookie } from "@src/hooks";

/* Utils */
import { getCookie } from "@utils/cookie";
import { withSuspense } from "@utils/withSuspense";
import { JWT_ACCESS_EXPIRY, JWT_REFRESH_EXPIRY } from "@src/consts";

/* APIs */
import { useGenerateNewAccessToken } from "@src/service/auth";

type Props = {
  children?: React.ReactNode;
};

const Auth: React.FC<Props> = () => {
  const { dispatch, state } = useAuth();
  const { setModalOpen } = useLoginModal();
  const params = useSearchParams();
  const { setCookies, COOKIE_KEYS } = useCookie();

  const mut = useGenerateNewAccessToken();

  useEffect(() => {
    /* If third party authentication is used then update state to logged in */
    if (params.get("oauth") === "1") dispatch({ type: AUTH_ACTIONS.LOGIN });
  }, [dispatch, params]);

  useEffect(() => {
    /* If login is set in the params then open modal */
    if (params.get("login") === "1") setModalOpen({ isLoginOpen: true });
  }, [params, setModalOpen]);

  useEffect(() => {
    /* 
      Run this timeout after every 10 minutes so that the access token is refreshed.
      The access token is set to expire after 15 minutes.
    */
    const interval = setInterval(async () => {
      const refreshToken = getCookie("refresh_token");
      if (!state.isLoggedIn || !refreshToken) return;

      const res = await mut.mutateAsync(refreshToken);

      if (res.data) {
        setCookies(
          [
            {
              name: COOKIE_KEYS.AUTH_TOKEN,
              value: res.data.accessToken,
              hours: JWT_ACCESS_EXPIRY,
            },
            {
              name: COOKIE_KEYS.REFRESH_TOKEN,
              value: res.data.refreshToken,
              hours: JWT_REFRESH_EXPIRY,
            },
          ],
          {
            path: "/",
          },
        );
      }
    }, 1000 * 60 * 10); // 10 min

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoggedIn]);

  return <></>;
};

export const AuthComponent = withSuspense(Auth, () => <></>);
