"use client";

import React, { useEffect } from "react";
import { usePathname } from "next/navigation";
import { removeLocalStoragevalue } from "@utils/localStorage";
import { LOCAL_STORAGE_KEYS } from "@src/consts";

type Props = {
  children?: React.ReactNode;
};

export const RouteEvent: React.FC<Props> = (props) => {
  const {} = props;

  const pathName = usePathname();

  useEffect(() => {
    /* 
      Remove courseId from local storage if not in correct route
      Usecase - To avoid presisting course id across multiple course modifications 
                so to avoid wrong course from being modified.
    */
    RemoveCourseId(pathName);
  }, [pathName]);

  return <></>;
};

const RemoveCourseId = (path: string) => {
  const VALID_ROUTES = [
    /\/admin\/course\/edit\/[-\w]+/g,
    /\admin\/course\/create/,
  ];

  const isValid = VALID_ROUTES.some((route) => route.test(path));
  if (
    !isValid &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.courseCreation.COURSE_ID)
  )
    removeLocalStoragevalue(LOCAL_STORAGE_KEYS.courseCreation.COURSE_ID);
};
