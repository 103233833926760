"use client";
import React, { useEffect, useLayoutEffect, useMemo } from "react";

/* Stores */
import { useTheme } from "@stores";

/* Hooks */
import { useLocalStorage, usePrefersDarkMode } from "@src/hooks";

type Props = {
  children?: React.ReactNode;
};

export const ThemeComponent: React.FC<Props> = (props) => {
  const {} = props;

  /* Hooks and Stores */
  const localStorage = useLocalStorage();
  const prefersDarkMode = usePrefersDarkMode();
  const { currentTheme, setTheme } = useTheme();

  useLayoutEffect(() => {
    // Find local storage value
    const localTheme = localStorage.get(localStorage.KEYS.THEME);
    if (localTheme) {
      setTheme(localTheme);
      return;
    }

    // If no local storage value, set theme based on prefersDarkMode
    if (prefersDarkMode) {
      setTheme("dark");
      localStorage.set(localStorage.KEYS.THEME, "dark");
    } else {
      setTheme("light");
      localStorage.set(localStorage.KEYS.THEME, "light");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefersDarkMode]);

  useLayoutEffect(() => {
    document.documentElement.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);

  return <></>;
};
