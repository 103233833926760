"use client";

import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";

/* Providers */
import { Events } from "./events";
import { AuthProvider } from "@src/contexts/auth";

/* Components */
import { AuthComponent } from "@src/shared/auth/auth";

/* Clients */
import { queryClient } from "@service/queryClient";

function Providers({ children }: React.PropsWithChildren) {
  const [client] = React.useState(queryClient);

  return (
    <AuthProvider>
      <QueryClientProvider client={client}>
        <ReactQueryStreamedHydration>
          <AuthComponent />
          <Events />
          {children}
        </ReactQueryStreamedHydration>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default Providers;
