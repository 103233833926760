"use client";

import React from "react";
import { RouteEvent } from "../src/events/route";

type Props = {
  children?: React.ReactNode;
};

export const Events: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <>
      <RouteEvent />
    </>
  );
};
