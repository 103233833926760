import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: false,
      staleTime: 300000,
      retry: 0,
      gcTime: 600000,
    },
  },
});

export const RefetchMultipleQueries = async (keys: (string | string[])[]) => {
  const promises = keys.map((key) => {
    queryClient.refetchQueries({
      queryKey: Array.isArray(key) ? [key] : [key],
    });
  });

  await Promise.all(promises);

  return true;
};
